<template>
  <div class="w-full bg-neutral-100 latest-news-and-article" v-lazy-background data-bg="/latest-news-and-article/bg.svg">
    <div class="lg:flex lg:flex-row grid grid-cols-1 lg:space-x items-center justify-center">
      <div
        class="flex justify-center text-center mt-10 text-neutral-900 lg:text-[48px] text-[40px] font-medium font-['Montserrat'] capitalize lg:leading-[85px] leading-[50px] lg:w-[60%] w-full sm:pr-10"
      >
        Latest
        News &
        Articles
      </div>
    </div>
    <CommonHorizontalCarousel
      :items="items"
      :items-to-show="itemsToShow"
      :auto-play="2000"
      control-classes="hidden"
      c-slide-classes=""
      :mouse-drag="true"
      :touch-drag="true"
    >
      <template #default="{ item, index }">
        <div class="flex flex-col items-center p-5 news-and-article-item">
          <img v-lazy-load 
            :class="`${index} ${index % 2 === 0 ? 'mt-10%' : ''}`"
            :data-src="item.img"
            class="h-[595px] w-full block object-cover object-center rounded-3xl"
          >
          <div class="text-neutral-900 text-xl font-medium font-['Montserrat'] mt-5 text-center">
            {{ item.text }}
          </div>
          <a :href="item.link" target="_blank" class="flex flex-row items-center text-orange-600 mt-3 pb-10">
            <span>Learn More</span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5 12H19M19 12L12 19M19 12L12 5"
                stroke="#E54825"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
      </template>
    </CommonHorizontalCarousel>
  </div>
</template>

<script setup lang="ts">

const items = [
  {
    img: '/latest-news-and-article/news-2.webp',
    text: 'How to Easily Navigate Insurance Claims for Roof Repairs: 5 Steps for a Smooth Process',
    link: 'https://www.gaf.com/en-us/plan-design/homeowner-education/roof-insurance-claims'
  },
  {
    img: '/latest-news-and-article/news-3.webp',
    text: 'Choosing the Right Roof Type: A Guide to Different Roofing Materials and Their Benefits',
    link: 'https://www.gaf.com/en-us/blog/your-home/choosing-the-right-roofing-materials-for-your-house-281474980000832'
  },
  {
    img: '/latest-news-and-article/news-4.webp',
    text: 'Tax Refund Home Upgrades: Boost Property Value With These Upgrades',
    link: 'https://mmroofsiding.com/tax-refund-home-upgrades-to-boost-property-value/'
  },
  {
    img: '/latest-news-and-article/news-1.webp',
    text: 'Choosing the Right Roof Type: A Guide to Different Roofing Materials and Their Benefits',
    link: 'https://mmroofsiding.com/choosing-the-right-roof-type/'
  }
]

const { width } = useWindowSize()
const itemsToShow = computed(() => (width.value <= 1024 ? 1 : 3))
</script>

<style scoped>
.latest-news-and-article {
  background-position: right 25%;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
